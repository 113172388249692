import React, { useState, useEffect, useRef } from "react";
import { withRouter, useParams } from "react-router-dom";
import useDetectKeyboardOpen from "use-detect-keyboard-open";
import { useTranslation } from "react-i18next";
import moment from "moment";

import MapComponent from "../components/MapComponent";

import {
  getBoundsForMarina,
  getPublicMooringsForMarina,
  getPublicMooringInfo,
} from "../actions/moorings";
import { getPublicAccountInfo } from "../actions/accounts";
import { searchMoorings } from "../actions/moorings";
import { getPublicMapOverlays } from "../actions/map_overlays";

import BpBanner from "../assets/bp_banner.png";
import DropDownIcon from "../assets/icons/dropdown.png";

import GetInAppStore from "../assets/get_in_app_store.png";
import GetInPlayStore from "../assets/get_in_play_store.png";
import CenterIcon from "../assets/center.png";
import FormInput from "../components/FormInput";
import MenuIcon from "../assets/menu.png";

const FILTER_MAX_LENGTH = 25;
const FILTER_MAX_BEAM = 10;
const FILTER_MAX_DRAFT = 10;

const initialFilter = {
  showUnavailable: false,
  showBouyPlaces: true,
  showExcludingMarinaFee: true,
  showPois: true,
  showAppInvisible: true,
  length_low: "",
  length_high: FILTER_MAX_LENGTH,
  beam_low: "",
  beam_high: FILTER_MAX_BEAM,
  draft_low: 0.0,
  draft_high: FILTER_MAX_DRAFT,
  draft_deviation: 0.0,
  ignoreMooringId: false,
  // showPois: true,
};

function getMobileOperatingSystem() {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return "Windows Phone";
  }

  if (/android/i.test(userAgent)) {
    return "Android";
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "iOS";
  }

  return "unknown";
}

const styles = {
  bottomImg: {
    height: 35,
    borderRadius: 5,
    background: "rgba(0, 0, 0, 0.6)",
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 5,
    paddingBottom: 5,
    marginLeft: 5,
    marginRight: 5,
    float: "left",
  },
};

function MooringMap() {
  const isInitialRender = useRef(true);
  const [bounds, setBounds] = useState(null);
  const [geoLocationAvailable, setGeoLocationAvailable] = useState(false);
  const [map, setMap] = useState(null);
  const [mavOverlays, setMapOverlays] = useState([]);
  const [account, setAccount] = useState(null);
  const [moorings, setMoorings] = useState(null);
  const [marinaMoorings, setMarinaMoorings] = useState([]);
  const [filter, setFilter] = useState(initialFilter);
  const [fromDate, setFromDate] = useState(moment());
  const [toDate, setToDate] = useState(moment().add(1, "day"));
  const [showFilter, setShowFilter] = useState(false);
  const [showCheckInModal, setshowCheckInModal] = useState(true);
  const [showDownloadModal, setShowDownloadModal] = useState(true);
  const [showMarinaInfo, setShowMarinaInfo] = useState(false);
  const [showBookOtherMooring, setShowBookOtherMooring] = useState(false);
  const [searchString, setsearchString] = useState("");
  const [userLocation, setUserLocation] = useState(false);
  const [otherMooring, setOtherMooring] = useState(false);
  const [checkin, setCheckin] = useState(false);
  const [findAndBook, setFindAndBook] = useState(true);

  const { t, i18n } = useTranslation();
  const isKeyboardOpen = useDetectKeyboardOpen();
  const platform = getMobileOperatingSystem();

  const { marina_id } = useParams();

  const allowAppDownloadModal = platform == "Android" || platform == "iOS";

  useEffect(() => {
    function viewportHandler(event) {
      var viewport = event.target;
      console.log("viewport.height", viewport.height);
    }

    window.visualViewport.addEventListener("scroll", viewportHandler);
    window.visualViewport.addEventListener("resize", viewportHandler);
  });

  // Marina ID
  useEffect(() => {
    if (marina_id) {
      getBoundsForMarina(
        marina_id,
        (initial_bounds) => {
          setBounds(initial_bounds);
          refreshMoorings(initial_bounds);
        },
        (error) => {}
      );

      getPublicAccountInfo(marina_id, (account) => {
        setAccount(account);
        if (!account.allow_booking_unreleased) {
          setCheckin(false);
          setFindAndBook(true);
          setshowCheckInModal(false);
          setShowMarinaInfo(true);
        }
      });

      getPublicMapOverlays(
        (map_overlays) => {
          setMapOverlays(map_overlays);
        },
        () => {}
      );
    }
    // Check if device has geo location
    if ("geolocation" in navigator) {
      setGeoLocationAvailable(true);
    }
  }, [marina_id]);

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }
    refreshMoorings();
  }, [filter, fromDate, toDate]);

  const refreshMoorings = (post_bounds = null) => {
    if (!post_bounds) post_bounds = bounds;
    var post_filter = {
      ...filter,
      marina_id: account?.account_id,
    };

    // Set non numeric values to 0
    Object.keys(post_filter).forEach(function (key, index) {
      if (post_filter[key] === "" || isNaN(post_filter[key]))
        post_filter[key] = 0;
    });

    searchMoorings(
      fromDate,
      toDate,
      post_bounds,
      post_filter,
      (moorings) => {
        setMoorings(moorings);
      },
      () => {}
    );

    getPublicMooringsForMarina(marina_id, (moorings) => {
      setMarinaMoorings(moorings);
    });
  };

  const onDragEnd = (bounds) => {
    refreshMoorings(bounds);
  };

  const getGeoLocation = () => {
    if (!geoLocationAvailable) return;
    navigator.geolocation.getCurrentPosition((position) => {
      const center = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      };
      setUserLocation(position.coords);
      map.panTo(center);
    });
  };

  const onOtherMooringClick = (mooring) => {
    console.log("mooring", mooring);

    setOtherMooring(mooring);
    setShowBookOtherMooring(false);
    setShowFilter(false);
  };

  var filtered_moorings = moorings?.filter((mooring) =>
    mooring.berth_number.toLowerCase().includes(searchString.toLowerCase())
  );
  filtered_moorings = findAndBook ? filtered_moorings : [];

  const length_low = !isNaN(Number(filter.length_low))
    ? Number(filter.length_low)
    : 0;
  const beam_low = !isNaN(Number(filter.beam_low))
    ? Number(filter.beam_low)
    : 0;

  const filtered_marina_moorings = marinaMoorings?.filter(
    (mooring) =>
      mooring.berth_number.toLowerCase().includes(searchString.toLowerCase()) &&
      mooring.max_length > length_low &&
      mooring.max_beam > beam_low
  );
  return (
    <div id="page" style={{ height: "100%" }}>
      <div style={{ height: "100%", width: "100%" }}>
        {moorings && (
          <MapComponent
            onMapRef={(map_ref) => {
              setMap(map_ref);
            }}
            from_date={fromDate}
            to_date={toDate}
            bounds={bounds}
            map_overlays={mavOverlays}
            moorings={filtered_moorings}
            otherMooring={otherMooring}
            onDragEnd={onDragEnd}
            onMapClicked={() => {
              setShowMarinaInfo(false);
              setShowBookOtherMooring(false);
            }}
            geoLocationAvailable={geoLocationAvailable}
            userLocation={userLocation}
            filter={filter}
          />
        )}
      </div>

      {showCheckInModal && (
        <div
          style={{
            ...MooringMapStyles.modal_container,
            ...MooringMapStyles.modal_container_top,
          }}
        >
          {moorings && (
            <div>
              <div style={MooringMapStyles.modal_content}>
                {t("BpmsMooringMap.whatDoYouWantToDo")}
                <div>
                  <button
                    className="button"
                    onClick={() => {
                      setCheckin(true);
                      setFindAndBook(false);
                      setshowCheckInModal(false);
                      setShowFilter(true);
                      setShowBookOtherMooring(true);
                      setShowDownloadModal(false);
                    }}
                  >
                    {t("BpmsMooringMap.checkinAndPay")}
                  </button>
                  <button
                    className="button"
                    onClick={() => {
                      setCheckin(false);
                      setFindAndBook(true);
                      setshowCheckInModal(false);
                      setShowMarinaInfo(true);
                      setShowDownloadModal(false);
                    }}
                  >
                    {t("BpmsMooringMap.findAndBook")}
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      )}

      {showMarinaInfo && (
        <div
          style={{
            ...MooringMapStyles.modal_container,
            ...MooringMapStyles.modal_container_top,
          }}
        >
          {moorings && (
            <div>
              <div style={MooringMapStyles.modal_content}>
                {filtered_moorings.length > 0
                  ? t("BpmsMooringMap.goToMooring")
                  : t("BpmsMooringMap.noMooringAvailable")}{" "}
                {account.bpms_comment ? account.bpms_comment : ""}
                <div>
                  <button
                    className="button"
                    onClick={() => {
                      setShowMarinaInfo(false);
                      setShowDownloadModal(false);
                      setShowFilter(true);
                    }}
                  >
                    {t("global.ok")}
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      )}

      {showDownloadModal && allowAppDownloadModal && (
        <div
          style={{
            ...MooringMapStyles.modal_container,
            ...MooringMapStyles.modal_container_bottom,
          }}
        >
          {moorings && (
            <div>
              <div style={MooringMapStyles.modal_content}>
                {t("BpmsMooringMap.downloadNotice")}
                <div>
                  <div>
                    {platform !== "Android" && ( // Apple App Store
                      <a href="https://apps.apple.com/ch/app/boatpark/id1466777753">
                        <img
                          style={{
                            ...styles.bottomImg,
                            marginTop: 10,
                            float: "none",
                          }}
                          src={GetInAppStore}
                          alt="AppStore"
                        />
                      </a>
                    )}
                    {platform !== "iOS" && ( // Android Google Play Store
                      <a href="https://play.google.com/store/apps/details?id=com.boatpark&hl=de_CH&gl=US">
                        <img
                          style={{
                            ...styles.bottomImg,
                            marginTop: 10,
                            float: "none",
                          }}
                          src={GetInPlayStore}
                          alt="PlayStore"
                        />
                      </a>
                    )}
                  </div>
                  <button
                    className="button"
                    onClick={() => {
                      setShowDownloadModal(false);
                    }}
                  >
                    {t("global.close")}
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      )}

      {/* Filter */}
      <div // Container
        id="filter_container"
        style={MooringMapStyles.filterCollapseContainer}
      >
        {moorings && (
          <div>
            {/* Title (clickable, open/close filter) */}
            <div
              style={MooringMapStyles.titleContainer}
              onClick={() => {
                setShowFilter(!showFilter);
              }}
            >
              <span id="filter_span" style={MooringMapStyles.TitleText}>
                {t("BpmsMooringMap.filter")}
              </span>
            </div>

            {/* Drawer (2d Horizontal and vertical) */}
            <div
              style={{
                ...MooringMapStyles.drawer2d,
                maxWidth: showFilter ? "500px" : "0px",
                maxHeight: showFilter ? "90vh" : 0,
              }}
            >
              <form>
                <div style={{ display: "flex" }}>
                  <form style={{ width: "50%" }}>
                    <FormInput
                      id="input_search"
                      label={t(`BpmsMooringMap.berthNr`)}
                      type="text"
                      value={searchString}
                      onChange={(e) => {
                        setsearchString(e.target.value);
                      }}
                      labelStyle={{ color: "white", fontSize: 14 }}
                      upperCastLabel={false}
                    />
                    <input
                      hidden
                      type="submit"
                      onClick={(e) => {
                        e.preventDefault();
                        document.activeElement.blur();
                      }}
                    />
                  </form>

                  <form style={{ display: "flex", width: "50%" }}>
                    <div style={{ width: "50%" }}>
                      <FormInput
                        id="input_boat_length"
                        label={t(`BpmsMooringMap.length`)}
                        type="number"
                        unit="m"
                        value={filter.length_low}
                        onChange={(e) => {
                          setFilter({ ...filter, length_low: e.target.value });
                        }}
                        min={0}
                        step="0.01"
                        labelStyle={{ color: "white", fontSize: 14 }}
                        upperCastLabel={false}
                      />
                    </div>
                    <div style={{ width: "50%" }}>
                      <FormInput
                        id="input_boat_beam"
                        label={t(`BpmsMooringMap.beam`)}
                        type="number"
                        unit="m"
                        value={filter.beam_low}
                        onChange={(e) => {
                          setFilter({ ...filter, beam_low: e.target.value });
                        }}
                        min={0}
                        step="0.01"
                        labelStyle={{ color: "white", fontSize: 14 }}
                        upperCastLabel={false}
                        onFocus={(e) => {
                          console.log("On focus");
                        }}
                      />
                    </div>
                    <input
                      hidden
                      type="submit"
                      onClick={(e) => {
                        e.preventDefault();
                        document.activeElement.blur();
                      }}
                    />
                  </form>
                </div>
              </form>

              {/* Section book other mooring */}
              {account?.allow_booking_unreleased && (
                <section>
                  <div style={{ width: "100%", overflow: "hidden" }}>
                    {checkin && (
                      <button
                        style={MooringMapStyles.collapseButton}
                        onClick={() =>
                          setShowBookOtherMooring(!showBookOtherMooring)
                        }
                      >
                        <div style={{ float: "left", marginRight: 10 }}>
                          {t("BpmsMooringMap.checkinAndPayOther")}
                        </div>

                        <img
                          style={{
                            ...MooringMapStyles.dropDownArrow,
                            transform: showBookOtherMooring ? "scaleY(-1)" : "",
                          }}
                          src={DropDownIcon}
                          alt="DropDownIcon"
                          className="spin"
                        />
                      </button>
                    )}
                  </div>

                  {/* List of all moorings at this marina */}
                  <div
                    style={{
                      ...MooringMapStyles.collapseVertical,
                      maxHeight: showBookOtherMooring ? "80vh" : 0,
                    }}
                  >
                    <div>
                      {filtered_marina_moorings.map((mooring, index) => (
                        <div
                          style={MooringMapStyles.MooringItemContainer}
                          id={"mooring_item_" + index}
                          key={index}
                        >
                          <div
                            onClick={() => {
                              onOtherMooringClick(mooring);
                            }}
                            style={{ color: "black", textDecoration: "none" }}
                            href={`${process.env.REACT_APP_WEB_APP_LINK}FastBooking/${mooring.mooring_id}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {mooring.harbour_name} {mooring.berth_number}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </section>
              )}
            </div>
          </div>
        )}
      </div>

      {/* Footer */}

      <div style={{ position: "relative" }}>
        <div
          style={{
            position: "fixed",
            bottom: isKeyboardOpen ? -120 : 15,
            width: "100%",
            margin: 0,
            padding: 0,
          }}
        >
          <a href="https://boatpark.app/" target="_blank" rel="noreferrer">
            {/* <img style={{ height: 45, marginLeft: 5, marginRight: 5, }} src={BpIcon} alt="Logo" /> */}
            <img style={styles.bottomImg} src={BpBanner} alt="BP Logo" />
          </a>
          {platform !== "Android" && ( // Apple App Store
            <a href="https://apps.apple.com/ch/app/boatpark/id1466777753">
              <img
                style={styles.bottomImg}
                src={GetInAppStore}
                alt="AppStore"
              />
            </a>
          )}
          {platform !== "iOS" && ( // Android Google Play Store
            <a href="https://play.google.com/store/apps/details?id=com.boatpark&hl=de_CH&gl=US">
              <img
                style={styles.bottomImg}
                src={GetInPlayStore}
                alt="PlayStore"
              />
            </a>
          )}
          {geoLocationAvailable && (
            <img
              style={{ ...styles.bottomImg, float: "right" }}
              src={CenterIcon}
              alt="BP Logo"
              onClick={() => {
                getGeoLocation();
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
}

const MooringMapStyles = {
  filterCollapseContainer: {
    position: "absolute",
    top: 5,
    left: 0,
    background: "rgba(0,0,0,0.6)",
    padding: 10,
    margin: 10,
    borderRadius: 10,
  },
  titleContainer: { overflow: "hidden", textAlign: "left", cursor: "pointer" },
  TitleText: { fontWeight: "bold", display: "inline-block", fontSize: "21px" },

  drawer2d: {
    overflowY: "auto",
    transition: "max-width 0.25s ease-in-out, max-height 0.25s ease-in-out",
    overflow: "hidden",
  },
  collapseButton: {
    float: "left",
    color: "white",
    fontSize: 18,
    height: 45,
    fontWeight: "bold",
    padding: 10,
    marginTop: 10,
    marginBottom: 10,
    border: "1px solid white",
    borderRadius: 5,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    cursor: "pointer",
  },
  dropDownArrow: {
    transition: "transform 250ms ease", // smooth transition
    float: "right",
    height: 10,
    marginRight: 10,
    marginTop: 8,
    color: "white",
  },
  collapseVertical: {
    transition: "max-height 0.15s ease-out",
    background: "white",
    color: "black",
    overflowY: "scroll",
  },
  MooringItemContainer: {
    borderBottom: "1px solid #ccc",
    padding: 5,
  },
  MooringMapPopup: {
    background: "rgba(0,0,0,0.6)",
    position: "absolute",
    top: "20%",
    left: "5%",
    width: "90%",
    height: 100,
    zIndex: 100,
  },

  modal_container: {
    position: "absolute",
    left: "5%",
    width: "90%",
    height: 100,
    zIndex: 100,
  },
  modal_container_top: {
    top: "20%",
  },
  modal_container_bottom: {
    bottom: "20%",
  },
  modal_content: {
    background: "rgba(0,0,0,0.6)",
    padding: 20,
    borderRadius: 10,
    maxWidth: 600,
    margin: "auto",
    marginTop: "100",
  },
};

export default withRouter(MooringMap);
